<template>
  <v-btn
    class="breakout-control"
    @click="$_BreakoutMixin_toggle"
    :class="{
      'breakout-control--active': $_BreakoutMixin_isHuddle
    }"
  >
    <svg-icon
      class="breakout-control__icon"
      :class="{ 'breakout-control__icon--active': $_BreakoutMixin_isHuddle }"
      size="small"
      name="breakouts"
    />
    <span> {{ $_BreakoutMixin_isHuddle ? "END BREAKOUT" : "BREAKOUTS" }} </span>
    <button class="breakout-control__edit" @click.stop="show($event)">
      <v-icon style="color: white" small>more_vert</v-icon>
    </button>
  </v-btn>
</template>

<script>
import Vue from "vue"
import BreakoutMixin from "@/components/BreakoutTypeDialog/BreakoutMixin"

export default Vue.extend({
  name: "BreakoutControl",
  mixins: [BreakoutMixin],
  methods: {
    show(e) {
      e.target.blur()
      this.$_BreakoutMixin_visibility = true
    }
  }
})
</script>

<style lang="scss">
.breakout-control {
  display: inline-flex;
  position: relative;
  vertical-align: top;
  height: 40px;
  width: 160px;
  color: #fff !important;
  border-radius: 25px;
  border: 1px solid #787a7c;
  background: transparent !important;
  font-size: 12px;
  font-weight: bold;

  &__edit {
    margin-left: auto;
    margin-right: 16px;

    &:hover {
      background: #798294;
      border-radius: 4px;
    }
  }

  .v-btn__content {
    justify-content: normal;
  }

  &:hover {
    background: #586475;
  }

  &__icon {
    margin-left: 16px;
    margin-right: 18px;

    &--active {
      margin-left: 16px;
      margin-right: 8px;
    }
  }

  &--active {
    background: #798294 !important;
    border: 1px solid #fff;
    .icon span {
      background: #fff !important;
    }
  }
}
</style>
