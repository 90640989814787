<template>
  <RtbControlButton
    v-if="state.value"
    class="main-drawer-toggle-btn"
    sm
    @click="close"
  >
    <v-icon dark key="close" style="margin: -4px 0">close</v-icon>
  </RtbControlButton>
  <RtbControlButton v-else class="main-drawer-toggle-btn" sm @click="open">
    <v-icon dark medium style="margin: -7px -10px -7px 0">
      keyboard_arrow_left
    </v-icon>
    <v-icon dark medium style="margin: -7px 0">more_vert</v-icon>
  </RtbControlButton>
</template>

<script>
import Vue from "vue"
import { RtbControlButton } from "@/components/ui"
import state, { open, close } from "@/components/drawers/main"

export default Vue.extend({
  name: "PickTeamsBottomBar",
  components: {
    RtbControlButton
  },
  data() {
    return {
      state
    }
  },
  methods: {
    open,
    close
  }
})
</script>

<style lang="scss">
.main-drawer-toggle-btn {
  width: 60px;
}
</style>
