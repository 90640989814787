var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-btn",
    {
      staticClass: "breakout-control",
      class: {
        "breakout-control--active": _vm.$_BreakoutMixin_isHuddle,
      },
      on: { click: _vm.$_BreakoutMixin_toggle },
    },
    [
      _c("svg-icon", {
        staticClass: "breakout-control__icon",
        class: {
          "breakout-control__icon--active": _vm.$_BreakoutMixin_isHuddle,
        },
        attrs: { size: "small", name: "breakouts" },
      }),
      _c("span", [
        _vm._v(
          " " +
            _vm._s(
              _vm.$_BreakoutMixin_isHuddle ? "END BREAKOUT" : "BREAKOUTS"
            ) +
            " "
        ),
      ]),
      _c(
        "button",
        {
          staticClass: "breakout-control__edit",
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.show($event)
            },
          },
        },
        [
          _c(
            "v-icon",
            { staticStyle: { color: "white" }, attrs: { small: "" } },
            [_vm._v("more_vert")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }